@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Ultralight.otf') format('woff2');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-UltralightItalic.otf') format('woff2');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Thin.otf') format('woff2');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-ThinItalic.otf') format('woff2');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Light.otf') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-LightItalic.otf') format('woff2');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Regular.otf') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-RegularItalic.otf') format('woff2');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Medium.otf') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-MediumItalic.otf') format('woff2');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Semibold.otf') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-SemiboldItalic.otf') format('woff2');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Bold.otf') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-BoldItalic.otf') format('woff2');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Heavy.otf') format('woff2');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-HeavyItalic.otf') format('woff2');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-Black.otf') format('woff2');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('../assets/fonts/SF-Pro-Text-BlackItalic.otf') format('woff2');
	font-weight: 900;
	font-style: italic;
}

* {
	font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
		sans-serif;
}
